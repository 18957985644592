import React, { useEffect, useState, useReducer, createContext } from "react";
import axios from "axios";

import { Grid } from "@mui/material";

import userReducer from "../reducers/user-reducer";
import introducerReducer from "../reducers/introducer-reducer";
import currencyReducer from "../reducers/currency-reducer";

import { setUser, setIntroducer, setCurrency } from "../actions";

import { useQuery } from "@apollo/client";
import { GET_PAYMENT_CURRENCIES } from "../../apollo/queries";

import getCountryCurrencyCode from "../../functions/getCountryCurrencyCode";

import Logo from "../../images/Logo";
import SpinningLogo from "../../components/ui/spinning-logo";

export const UserContext = createContext();
const UserProvider = UserContext.Provider;

export const IntroducerContext = createContext();
export const CurrencyContext = createContext();

export function UserWrapper({ children }) {
  const [isLoading, setIsLoading] = useState(true);
  const guestUserID = process.env.GATSBY_GUEST_ID ?? 0;

  const [defaultUser, setDefaultUser] = useState({
    id: Number(guestUserID),
    username: "Guest",
    email: "guest@chikitea.com",
    country: null,
  });

  const storedUser =
    typeof window !== "undefined"
      ? JSON.parse(localStorage.getItem("user"))
      : null;

  //console.log("storedUser:", storedUser);
  const userToBeSet = storedUser || defaultUser;
  //console.log("userToBeSet:", userToBeSet);

  let [user, dispatchUser] = useReducer(userReducer, userToBeSet);

  const defaultIntroducer = { introducedByCode: null };
  const defaultCurrency = {
    currency: "UK Pounds",
    codeISO: "GBP",
    country: "United Kingdom",
  };

  const storedIntroducer =
    typeof window !== "undefined"
      ? JSON.parse(localStorage.getItem("introducer"))
      : null;
  //console.log("storedIntroducer:", storedIntroducer);

  let [introducer, dispatchIntroducer] = useReducer(
    introducerReducer,
    storedIntroducer || defaultIntroducer
  );

  const storedCurrency =
    typeof window !== "undefined"
      ? JSON.parse(localStorage.getItem("currency"))
      : null;

  let [currency, dispatchCurrency] = useReducer(
    currencyReducer,
    storedCurrency || defaultCurrency
  );

  // query (via Apollo) for currency info
  const { loading, error, data } = useQuery(GET_PAYMENT_CURRENCIES, {
    fetchPolicy: "network-only", // Always fetch from the network
  });

  // updates user profile on page refresh (delay 1 second)

  useEffect(() => {
    //console.log("running useEffect...");
    // extract url parameters string
    let urlParams = window.location.search;
    //console.log("window.location.search:", window.location.search);

    if (urlParams.slice(0, 10) === "?provider=") {
      const [first, provider, remainder] = urlParams.split("?", 3);
      if (first) {
        //console.log("returned first element:", first);
      }
      urlParams = "?" + provider + "&" + remainder;
    }

    const params = new URLSearchParams(urlParams);
    //console.log("params:", params);

    // check for Password Reset code in URL parameters
    const code = params.get("code");
    //console.log("code:", code);

    // check for social sign in provider
    const provider = params.get("provider");
    //console.log("provider:", provider);

    const isSocialSignInOrPasswordReset = code || provider;
    //console.log(
    //  "isSocialSignInOrPasswordReset:",
    //  isSocialSignInOrPasswordReset
    //);

    if (!loading && data && !isSocialSignInOrPasswordReset) {
      let paymentCurrencies = data.currencies.data;
      // console.log("paymentCurrencies:", paymentCurrencies);

      // update user with IP info and introduction code in url paramters

      // extract url parameters string
      const params = new URLSearchParams(window.location.search);
      //console.log("URL params:", params);

      // check for introduction code in URL parameters
      let introducedByCode = params.get("introducer");

      let currentUser = storedUser || defaultUser;
      let updatedUser = storedUser || defaultUser;
      //console.log("currentUser:", currentUser, "updatedUser:", updatedUser);
      let currencyCode;

      if (introducedByCode) {
        // console.log("storedUser:", storedUser);
        if (storedUser) {
          currentUser = storedUser;
        }
        // console.log("currentUser:", currentUser);
        updatedUser = { ...currentUser, introducedByCode };
        console.log(
          "setting introducer with url parameter introducedByCode:",
          introducedByCode
        );
        dispatchIntroducer(setIntroducer({ introducedByCode }));
      } else if (storedIntroducer) {
        if (storedUser) {
          currentUser = storedUser;
        }
        introducedByCode = storedIntroducer.introducedByCode;
        if (introducedByCode) {
          updatedUser = { ...currentUser, introducedByCode };
          console.log(
            "setting user with stored introducedByCode:",
            updatedUser
          );
        }
      }

      // temporary fix
      //dispatchUser(setUser(updatedUser));

      // get ip info and set user
      axios
        //.get("https://geolocation-db.com/json/")
        .get("https://api.ipbase.com/v1/json/")
        .then((response) => {
          //console.log("IP response:", response);
          const country = response.data.country_name;

          //console.log("country:", country);
          if (country) {
            currencyCode = getCountryCurrencyCode(country);

            // get currency name from code
            let currency = null;
            paymentCurrencies.forEach((paymentCurrency) => {
              if (paymentCurrency.attributes.allowPayments) {
                //console.log("paymentCurrency:", paymentCurrency);
                if (currencyCode === paymentCurrency.attributes.codeISO) {
                  currency = paymentCurrency.attributes.name;
                }
              }
            });

            //console.log("currency:", currency);

            let updatedCurrency = currency
              ? {
                  codeISO: currencyCode,
                  currency,
                  country: country,
                }
              : defaultCurrency;

            //console.log("updatedCurrency:", updatedCurrency);

            dispatchCurrency(setCurrency(updatedCurrency));

            updatedUser = {
              ...updatedUser,
              country,
              currency: updatedCurrency.currency,
              codeISO: updatedCurrency.codeISO,
            };
            console.log("setting user with IP country:", updatedUser);
            dispatchUser(setUser(updatedUser));
          }
        })
        .catch((error) => {
          console.error(error);
          console.log(
            "IP api connection failed. Setting default user with default country: 'GB':",
            updatedUser
          );
          dispatchUser(setUser(updatedUser));
        });

      if (storedUser) {
        // DISPATCH STORED USER
        setTimeout(() => {
          console.log("dispatching stored user...");
          //console.log("storedUser:", storedUser)

          // preserve loyaltyTier
          let loyaltyTier = null;
          if (storedUser.loyaltyTier) {
            loyaltyTier = storedUser.loyaltyTier;
          }

          axios
            .get(process.env.GATSBY_STRAPI_API_URL + "/api/users/me", {
              headers: {
                Authorization: `Bearer ${storedUser.jwt}`,
              },
              populate: "*",
            })
            .then((response) => {
              //console.log("USER WRAPPER: response.data:", response.data)

              let updatedUser;
              if (loyaltyTier) {
                updatedUser = {
                  ...response.data,
                  loyaltyTier,
                  jwt: storedUser.jwt,
                  onboarding: true,
                };
              } else {
                updatedUser = {
                  ...response.data,
                  jwt: storedUser.jwt,
                  onboarding: true,
                };
              }
              //console.log("setting user:", updatedUser);
              dispatchUser(setUser(updatedUser));
              setIsLoading(false);
            })
            .catch((error) => {
              console.error(error);
              dispatchUser(setUser(updatedUser));
              setIsLoading(false);
            });
        }, 1500);
      } else {
        dispatchUser(setUser(updatedUser));
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
    }
    //console.log("completed useEffect");

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, data]);

  return (
    <UserProvider value={{ user, dispatchUser, defaultUser }}>
      {isLoading ? (
        <Grid
          item
          container
          justifyContent="center"
          alignItems="flex-start"
          backgroundColor="primary.veryDark"
          height={2400}
        >
          <Grid item marginTop={22}>
            <SpinningLogo Logo={Logo} size="150" color="#fff" />
          </Grid>
        </Grid>
      ) : (
        children
      )}
    </UserProvider>
  );
}
